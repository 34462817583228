import { useStaticQuery, graphql } from "gatsby"

export const useAllKontentResourceStringData = () => {
  const { allKontentItemResourceStrings } = useStaticQuery(
    graphql`
      query AllResourceStringsQuery {
        allKontentItemResourceStrings {
          nodes {
            elements {
              label {
                value
              }
            }
            id
            system {
              name
            }
          }
        }
      }
    `
  )

  return allKontentItemResourceStrings.nodes
}
