import React, { ReactNode } from "react"
import { Link } from "gatsby"

export interface ButtonProps {
  id?: string
  className?: string
  outline?: boolean
  color?: string
  children: ReactNode
  onClick?: (value: any) => void
  url?: string
  disabled?: boolean
}

export const Button = ({ id, className, color, onClick, children, outline, url, disabled }: ButtonProps) => {
  let typeClassName
  switch (color) {
    case "grey":
      typeClassName = outline ? "border border-gray-500 hover:bg-filteringPillsHover hover:text-white" : "text-white bg-filteringPillsHover hover:bg-transparent hover:text-gray-600 transition-colors focus:outline outline-1"
      break
    default:
      typeClassName = outline ? "border border-gray-500 hover:bg-filteringPillsHover hover:text-white" : "text-white bg-horizonred hover:bg-horizonhover hover:text-grey-300"
  }

  const combinedClassName = `${typeClassName} inline-flex items-center px-12 py-2 text-sm font-medium rounded-full ${className}`

  return url ? (
    <Link
      className={combinedClassName}
      to={url}
    >
      {children}
    </Link>
  ) : (
    <button
      id={id}
      onClick={onClick}
      className={combinedClassName}
      disabled={disabled}
    >
      {children}
    </button>
  )
}
