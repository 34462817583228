import { isArray } from "lodash";

export const getResourceStringByid = (resourceStrings: any, id: string): string => {
    try {
        if(!isArray(resourceStrings) || !resourceStrings.length || !id.length) {
            return '';
        }

         return resourceStrings.find((i: any) => i.id == id).elements.label.value;
    } 
    catch {
        console.log(`Could not find resource string with id ${id}`);
    }

    return '';
}