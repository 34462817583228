import React from "react"
import { UnauthenticatedResourcesStrings } from "../types/enums/unauthenticated-resource-strings"
import { getResourceStringByid } from "../utils/resource-string-helper"
import { useAllKontentResourceStringData } from "../graphql-static/use-resource-strings"
import Logo from "../images/horizon-icon.svg"
import { getPermissionRoles } from "../utils/getPermissions"
import { Button } from "../components/button"
import { useMsal } from "@azure/msal-react"

export default function Forbidden() {
  const resourceStrings = useAllKontentResourceStringData()
  const { instance } = useMsal()
  const permissions = getPermissionRoles()
  const noPermissions = !permissions || permissions.length === 0
  const handleOnLogout = () => {
    instance.logoutRedirect()
  }

  return (
    <>
      <main className="min-h-full px-4 py-16 bg-white sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <img
          src={Logo}
          className="w-auto h-32 mx-auto mt-8 mb-24"
          alt="Horizon logo"
        />
        <div className="mx-auto max-w-max sm:flex">
          <p className="text-4xl font-bold tracking-tight sm:text-5xl">403</p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                {getResourceStringByid(
                  resourceStrings,
                  UnauthenticatedResourcesStrings.INTERNAL_UNAUTHENTICATED_ERRORHANDLING_403_TITLE
                )}
              </h1>
              <p className="mt-1 text-base max-w-[335px] text-gray-500">
                {getResourceStringByid(
                  resourceStrings,
                  UnauthenticatedResourcesStrings.INTERNAL_UNAUTHENTICATED_ERRORHANDLING_403_MESSAGE
                )}
              </p>
            </div>
            <div className="flex mt-10 space-x-3 sm:border-l sm:border-transparent sm:pl-6">
              {noPermissions ? (
                <Button onClick={handleOnLogout}>
                  {getResourceStringByid(
                    resourceStrings,
                    UnauthenticatedResourcesStrings.INTERNAL_UNAUTHENTICATED_ERRORHANDLING_SIGNOUT_BUTTON
                  )}
                </Button>
              ) : (
                <a
                  href={getResourceStringByid(
                    resourceStrings,
                    UnauthenticatedResourcesStrings.INTERNAL_UNAUTHENTICATED_ERRORHANDLING_403_HREF
                  )}
                  className="inline-flex items-center px-12 py-2 text-sm font-medium text-white border-transparent rounded-full bg-horizonred hover:bg-horizonhover hover:text-grey-300"
                >
                  {getResourceStringByid(
                    resourceStrings,
                    UnauthenticatedResourcesStrings.INTERNAL_UNAUTHENTICATED_ERRORHANDLING_403_BUTTON
                  )}
                </a>
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  )
}
